






























import { Vue, Component, Watch } from "vue-property-decorator";
import Navbar from "../../components/Navbar.vue";
import FooterPage from "../../components/FooterPage.vue";
import AusterInput from "../../components/AusterInput.vue";
import { validateEntity } from "../../models/entity-validation";
import AusterButton from "../../components/AusterButton.vue";
import authModel from "@/models/auth.model";

@Component({
  components: {
    Navbar,
    FooterPage,
    AusterInput,
    AusterButton,
  },
})
export default class PasswordRecovery extends Vue {

  sendCode = false;
  passwordResetCode = "";
  newPassword = "";
  newPasswordConfirm = "";

  userInfo: any = {
    email: "",
    passwordResetCode : "",
    newPassword : "",
    newPasswordConfirm : "",
  };

  fieldErrors: any = {};

  loadingButton = false;
  disableButton = false;
  loadingButtonUpdate = false;
  disableButtonUpdate = false;

  changePage() {
    this.sendCode = true;
  }


  validateFieldsFirstStep() {
    const validationDefinition: any = [
      { key: "email", validation: { kind: "email" } },
    ];
    this.fieldErrors = validateEntity(this.userInfo, validationDefinition, ["email"]);
  }

  validateFieldsSecondStep() {
    const validationDefinition: any = [
      { key: "passwordResetCode", validation: { kind: "passwordResetCode" } },
      { key: "newPassword", validation: { lengthMin: 8, lengthMax: 100 } },
      { key: "newPasswordConfirm", validation: { kind: "match", matchKey: "newPassword" } }
      ];
    this.fieldErrors = validateEntity(this.userInfo, validationDefinition, ["passwordResetCode", "newPassword", "newPasswordConfirm"]);
  }

  @Watch('userInfo', { deep: true })
  userInfoChanged() {
    // if a previous error was found, update validation when data changes
    if (Object.keys(this.fieldErrors).length) {
      this.validateFieldsFirstStep();
      this.validateFieldsSecondStep();
    }
  }

  async sendRecoveryCode() {
    this.validateFieldsFirstStep();
    if (Object.values(this.fieldErrors).filter(error => !!error).length) {
      this.$vs.notification({
        text: "Verifique os erros indicados",
        color: "#FF6767",
      });
      console.error(this.fieldErrors);
      return;
    }
    try {
      this.loadingButton = true;
      this.disableButton = true;
      await authModel.requestPasswordReset(this.userInfo.email);
      this.$vs.notification({
        text: "Código enviado",
        color: "#2E7179",
      });
      this.sendCode = true;
    } catch (error) {
      console.error(error);
       this.$vs.notification({
        title: 'Erro',
        text: error.message,
        color: '#FF6767'
      });
      this.loadingButton = false;
      this.disableButton = false;
    }
  }

  async updatePassword() {
    this.validateFieldsSecondStep();
    if (Object.values(this.fieldErrors).filter(error => !!error).length) {
      this.$vs.notification({
        text: "Verifique os erros indicados",
        color: "#FF6767",
      });
      console.error(this.fieldErrors);
      return;
    }
    try {
      this.loadingButtonUpdate = true;
      this.disableButtonUpdate = true;
      await authModel.resetPassword(this.userInfo['passwordResetCode'], this.userInfo['newPassword'])
      this.$vs.notification({
        text: "Senha atualizada",
        color: "#2E7179",
      });
      this.$router.replace("/");
    } catch (error) {
      if (error.message === "Validation error") {
        error.message = "Existem campos invalidos";
      } else {
        error.message = "Não foi possível atualizar a senha. Tente novamente."
      }
        this.$vs.notification({
        title: 'Erro',
        text: error.message,
        color: '#FF6767'
      });
      console.error('ERROR', error);
      this.loadingButtonUpdate = false;
      this.disableButtonUpdate = false;
    }
  }
}
